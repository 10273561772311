import React from 'react';
import style from './page-intro.mod.scss';
import { graphql, useStaticQuery } from 'gatsby';
import Container from '../SharedStyles/Container';
import { Navigator } from '../../LanguageHelpers/Navigator';
import Button from '../../Button/Button';

export const PageIntro = ({ title, breadcrumbs, cta }) => {
  return (
    <div className={style.intro}>
      <Container width={'medium'}>
        <div className={style.intro__container}>
          <div className={style.intro__heading_container}>
            <Breadcrumbs items={breadcrumbs}></Breadcrumbs>
            <h2 className={style.intro__heading}>{title}</h2>
          </div>
          {cta && cta.length > 0 && <Cta cta={cta}>{'Click'}</Cta>}
        </div>
      </Container>
    </div>
  );
};

const Breadcrumbs = ({ items }) => {
  return (
    <div className={style.intro__breadcrumbs}>
      {items.map(({ title, id }) => (
        <span className={style.intro__breadcrumb}>
          <Navigator recordId={id}>{title}</Navigator>
          <span className={style.intro__divider}>{'/'}</span>
        </span>
      ))}
    </div>
  );
};

const Cta = ({ cta }) => {
  const ctaData = cta[0];
  return (
    <div className={style.intro__button_wrapper}>
      <Button
        light
        secondary
        size={'large'}
        className={style.intro__button}
        recordId={ctaData.page.id}
      >
        {ctaData.text}
      </Button>
    </div>
  );
};
