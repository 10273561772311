import React from 'react';
import style from './page-lead.mod.scss';
import Container from '../Layout/SharedStyles/Container';

const PageLead = ({ leadText, title }) => {
  return (
    <Container width={'small'}>
      <div className={style.pagelead}>
        <div className={style.pagelead__text}>
          {title && <h1>{title}</h1>}
          <p>{leadText}</p>
        </div>
        <hr/>
      </div>
    </Container>
  );
};
export default PageLead;
